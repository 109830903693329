// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../api/Api.res.js";
import * as Url from "../../utils/Url.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Routes_Product from "../../routes/common/Routes_Product.res.js";
import * as ProductCategory from "../../models/ProductCategory.res.js";
import * as ProviderAsFilter from "../../models/ProviderAsFilter.res.js";
import * as ColocationProduct from "../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorHeader from "../common/dashboard/DashboardProductEditorHeader.res.js";
import * as DashboardProductEditorLayout from "../common/dashboard/DashboardProductEditorLayout.res.js";
import * as DashboardProductFeatureEditor from "../common/dashboard/DashboardProductFeatureEditor.res.js";
import * as DashboardProductEditorActionBar from "../common/dashboard/DashboardProductEditorActionBar.res.js";
import * as DashboardProductEditorGeneralTab from "../common/dashboard/DashboardProductEditorGeneralTab.res.js";
import * as DashboardProductEditorGeneralTabData from "../common/dashboard/DashboardProductEditorGeneralTabData.res.js";

function DashboardCreateProduct$DashboardCreateProduct(props) {
  var categories = props.categories;
  var providers = props.providers;
  var initialState = React.useMemo((function () {
          return {
                  status: "Editing",
                  input: providers.NAME === "Many" ? DashboardProductEditorGeneralTabData.Input.empty(categories, undefined, undefined) : DashboardProductEditorGeneralTabData.Input.empty(categories, Caml_option.some(providers.VAL.id), undefined),
                  results: undefined,
                  submissionError: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "AddFeature" :
                  var match = state.status;
                  if (match !== "Editing") {
                    return "NoUpdate";
                  }
                  if (state.input.features.length >= ColocationProduct.maxFeatures) {
                    return "NoUpdate";
                  }
                  var init = state.input;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: {
                              name: init.name,
                              description: init.description,
                              providerId: init.providerId,
                              category: init.category,
                              subcategory: init.subcategory,
                              startDate: init.startDate,
                              endDate: init.endDate,
                              isExclusiveOffer: init.isExclusiveOffer,
                              pricePerMonth: init.pricePerMonth,
                              setupFee: init.setupFee,
                              term: init.term,
                              powerCircuit: init.powerCircuit,
                              bandwidth: init.bandwidth,
                              ipAllocation: init.ipAllocation,
                              totalPowerKw: init.totalPowerKw,
                              features: Belt_Array.concat(state.input.features, [DashboardProductFeatureEditor.Input.empty])
                            },
                            results: state.results,
                            submissionError: state.submissionError
                          }
                        };
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: state.input,
                            results: DashboardProductEditorGeneralTabData.Validate.all(state.input),
                            submissionError: state.submissionError
                          }
                        };
              case "Submit" :
                  var match$1 = state.status;
                  if (match$1 === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              input: state.input,
                              results: DashboardProductEditorGeneralTabData.Validate.all(state.input),
                              submissionError: state.submissionError
                            },
                            _1: (function (param) {
                                if (DashboardProductEditorGeneralTabData.Validate.valid(Belt_Option.getExn(param.state.results))) {
                                  return param.dispatch("CreateNewProduct");
                                }
                                
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "CreateNewProduct" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            input: state.input,
                            results: state.results,
                            submissionError: state.submissionError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.createProduct(param.state.input), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "GoToNextTab",
                                                    _0: x._0.id
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardCreateProduct",
                                            subModulePath: {
                                              hd: "DashboardCreateProduct",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardCreateProduct.DashboardCreateProduct.make"
                                          }, "DashboardWizardGeneralTab::CreateNewProduct::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailSubmission");
                                    }));
                            })
                        };
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            input: state.input,
                            results: state.results,
                            submissionError: true
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "Update" :
                  var input = action._0;
                  var match$2 = state.status;
                  if (match$2 !== "Editing") {
                    return "NoUpdate";
                  }
                  var match$3 = state.results;
                  if (match$3 !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              input: input,
                              results: state.results,
                              submissionError: state.submissionError
                            },
                            _1: (function (param) {
                                param.dispatch("Validate");
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              input: input,
                              results: state.results,
                              submissionError: state.submissionError
                            }
                          };
                  }
              case "UpdateFeature" :
                  var input$1 = action._1;
                  var idx = action._0;
                  var match$4 = state.status;
                  if (match$4 !== "Editing") {
                    return "NoUpdate";
                  }
                  var init$1 = state.input;
                  var state_status = state.status;
                  var state_input = {
                    name: init$1.name,
                    description: init$1.description,
                    providerId: init$1.providerId,
                    category: init$1.category,
                    subcategory: init$1.subcategory,
                    startDate: init$1.startDate,
                    endDate: init$1.endDate,
                    isExclusiveOffer: init$1.isExclusiveOffer,
                    pricePerMonth: init$1.pricePerMonth,
                    setupFee: init$1.setupFee,
                    term: init$1.term,
                    powerCircuit: init$1.powerCircuit,
                    bandwidth: init$1.bandwidth,
                    ipAllocation: init$1.ipAllocation,
                    totalPowerKw: init$1.totalPowerKw,
                    features: Belt_Array.mapWithIndex(state.input.features, (function (idx$p, input$p) {
                            if (idx$p === idx) {
                              return input$1;
                            } else {
                              return input$p;
                            }
                          }))
                  };
                  var state_results = state.results;
                  var state_submissionError = state.submissionError;
                  var state$1 = {
                    status: state_status,
                    input: state_input,
                    results: state_results,
                    submissionError: state_submissionError
                  };
                  var match$5 = state_results;
                  if (match$5 !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: state$1,
                            _1: (function (param) {
                                param.dispatch("Validate");
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: state$1
                          };
                  }
              case "RemoveFeature" :
                  var idx$1 = action._0;
                  var match$6 = state.status;
                  if (match$6 !== "Editing") {
                    return "NoUpdate";
                  }
                  var init$2 = state.input;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: {
                              name: init$2.name,
                              description: init$2.description,
                              providerId: init$2.providerId,
                              category: init$2.category,
                              subcategory: init$2.subcategory,
                              startDate: init$2.startDate,
                              endDate: init$2.endDate,
                              isExclusiveOffer: init$2.isExclusiveOffer,
                              pricePerMonth: init$2.pricePerMonth,
                              setupFee: init$2.setupFee,
                              term: init$2.term,
                              powerCircuit: init$2.powerCircuit,
                              bandwidth: init$2.bandwidth,
                              ipAllocation: init$2.ipAllocation,
                              totalPowerKw: init$2.totalPowerKw,
                              features: Belt_Array.keepWithIndex(state.input.features, (function (param, idx$p) {
                                      return idx$p !== idx$1;
                                    }))
                            },
                            results: Belt_Option.map(state.results, (function (results) {
                                    return {
                                            name: results.name,
                                            description: results.description,
                                            providerId: results.providerId,
                                            category: results.category,
                                            subcategory: results.subcategory,
                                            startDate: results.startDate,
                                            endDate: results.endDate,
                                            pricePerMonth: results.pricePerMonth,
                                            setupFee: results.setupFee,
                                            term: results.term,
                                            powerCircuit: results.powerCircuit,
                                            bandwidth: results.bandwidth,
                                            ipAllocation: results.ipAllocation,
                                            totalPowerKw: results.totalPowerKw,
                                            features: Belt_Array.keepWithIndex(results.features, (function (param, idx$p) {
                                                    return idx$p !== idx$1;
                                                  }))
                                          };
                                  })),
                            submissionError: state.submissionError
                          }
                        };
              case "GoToNextTab" :
                  var id = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              Url.visit(Routes_Product.Dashboard.editLocation(id));
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs(DashboardProductEditorLayout.make, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorHeader.make, {
                      product: undefined,
                      active: "GeneralTab"
                    }),
                JsxRuntime.jsx(DashboardProductEditorGeneralTab.make, {
                      input: state.input,
                      categories: categories,
                      providers: providers,
                      bandwidthOptions: props.bandwidthOptions,
                      ipAllocationOptions: props.ipAllocationOptions,
                      powerOptions: props.powerOptions,
                      termOptions: props.termOptions,
                      results: state.results,
                      update: (function (input) {
                          dispatch({
                                TAG: "Update",
                                _0: input
                              });
                        }),
                      addFeature: (function () {
                          dispatch("AddFeature");
                        }),
                      updateFeature: (function (idx, input) {
                          dispatch({
                                TAG: "UpdateFeature",
                                _0: idx,
                                _1: input
                              });
                        }),
                      removeFeature: (function (idx) {
                          dispatch({
                                TAG: "RemoveFeature",
                                _0: idx
                              });
                        }),
                      submit: (function (param) {
                          dispatch("Submit");
                        }),
                      children: JsxRuntime.jsxs(DashboardProductEditorActionBar.make, {
                            children: [
                              JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Gray",
                                    expanded: true,
                                    onClick: (function (param) {
                                        Url.visit(Routes_Product.Dashboard.index);
                                      }),
                                    children: "Cancel"
                                  }),
                              JsxRuntime.jsx(DashboardProductEditorActionBar.$$Error.make, {
                                    children: state.submissionError ? "Something went wrong" : null
                                  }),
                              JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Primary",
                                    expanded: true,
                                    busy: match$1 !== "Editing",
                                    submit: true,
                                    children: "Next"
                                  })
                            ]
                          })
                    })
              ]
            });
}

var DashboardCreateProduct = {
  Input: undefined,
  Validate: undefined,
  ValidationResult: undefined,
  make: DashboardCreateProduct$DashboardCreateProduct
};

function DashboardCreateProduct$default(props) {
  var props$1 = props.props;
  var providers = props$1.providers;
  var tmp;
  if (providers.length !== 1) {
    tmp = {
      NAME: "Many",
      VAL: Belt_Array.map(providers, ProviderAsFilter.fromJs)
    };
  } else {
    var provider = providers[0];
    tmp = {
      NAME: "One",
      VAL: ProviderAsFilter.fromJs(provider)
    };
  }
  return JsxRuntime.jsx(DashboardCreateProduct$DashboardCreateProduct, {
              providers: tmp,
              categories: Belt_Array.map(props$1.categories, ProductCategory.fromJs),
              bandwidthOptions: props$1.bandwidthOptions,
              ipAllocationOptions: props$1.ipAllocationOptions,
              powerOptions: props$1.powerOptions,
              termOptions: props$1.termOptions
            });
}

var $$default = DashboardCreateProduct$default;

export {
  DashboardCreateProduct ,
  $$default as default,
}
/* Api Not a pure module */
